// extracted by mini-css-extract-plugin
export var competitionContainer = "competition-module--competitionContainer--7578c";
export var competitionPage = "competition-module--competitionPage--210fb";
export var competitionText = "competition-module--competitionText--4ddde";
export var conditionList = "competition-module--conditionList--50aa1";
export var conditions = "competition-module--conditions--3e5a2";
export var conditionsHeader = "competition-module--conditionsHeader--0d4ae";
export var conditionsText = "competition-module--conditionsText--a66bb";
export var conditions_container = "competition-module--conditions_container--7ab51";
export var horizontalRuler = "competition-module--horizontalRuler--ad08c";
export var prize = "competition-module--prize--a8639";
export var prizeDescription = "competition-module--prizeDescription--5c7a3";
export var prizeFirst = "competition-module--prizeFirst--b6fee";
export var prizeSecond = "competition-module--prizeSecond--1c435";
export var prizeSquares = "competition-module--prizeSquares--54481";
export var prizeThird = "competition-module--prizeThird--56583";
export var prizeTitle = "competition-module--prizeTitle--3bdcb";
export var prizeType = "competition-module--prizeType--dcbd8";
export var squarePrize = "competition-module--squarePrize--f8af1";
export var whiteSection = "competition-module--whiteSection--62956";
export var winnersImage = "competition-module--winnersImage--9c514";